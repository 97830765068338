<template>
  <div class="container">
    <h1 class="mb-3">ENROLLMENT</h1>
    <el-tabs type="card">
      <el-tab-pane label="Enrollment form">
        <el-form
          @submit.prevent.native="submit"
          :model="form"
          :validate-on-rule-change="false"
          ref="form"
          label-position="top"
          label-width="100px"
        >
          <!-- ===== 填表人與學生的關係 ===== -->
          <el-form-item
            :label="$t('enrollment.step1.role')"
            :rules="[commonRules.radio, rules.role]"
            prop="role"
            required
          >
            <el-radio-group v-model="form.role">
              <template v-for="(role, index) in roleList">
                <template v-if="role !== otherRoleValue">
                  <el-radio :key="index" :label="role"></el-radio>
                </template>
                <!-- NOTE: 關係的最後一個必是【其它】：使用者自行填寫 -->
                <template v-else>
                  <br :key="`br-${index}`" class="d-sm-none" />
                  <el-radio :key="index" :label="role"></el-radio>
                  <el-input
                    :key="`input-${index}`"
                    v-model="otherRole"
                    :required="isOtherRole"
                    :disabled="!isOtherRole"
                    ref="otherRole"
                    style="width: 200px"
                  ></el-input>
                </template>
              </template>
            </el-radio-group>
          </el-form-item>

          <!-- ===== 填表人姓名 ===== -->
          <el-form-item
            :label="$t('enrollment.step1.firstName')"
            :rules="commonRules.input"
            prop="first_name"
            required
          >
            <el-input
              v-model="form.first_name"
              required
              :placeholder="$t('enrollment.step1.placeholderFirstName')"
            ></el-input>
          </el-form-item>

          <!-- ===== 填表人 email ===== -->
          <el-form-item
            :label="$t('enrollment.step1.email')"
            :rules="[commonRules.input, commonRules.email]"
            prop="email"
            required
          >
            <el-input
              v-model="form.email"
              type="email"
              required
              @change="checkEmail"
            ></el-input>
          </el-form-item>

          <!-- ===== 填表人電話 ===== -->
          <el-form-item
            :label="$t('enrollment.step1.phone')"
            :rules="commonRules.phone"
            prop="phone"
            required
          >
            <el-input v-model="form.phone" type="tel" required></el-input>
          </el-form-item>

          <!-- ===== 學生想上的課程 ===== -->
          <el-form-item
            :label="$t('enrollment.step1.classesToEnroll')"
            :rules="[commonRules.checkbox, rules.interested_courses]"
            prop="interested_courses"
            required
          >
            <el-checkbox-group v-model="form.interested_courses">
              <div v-for="course in courseList" :key="course.id">
                <el-checkbox
                  :label="course.name"
                  :class="{ 'd-flex': !course.show_extra_input }"
                  class="mt-3 align-items-center"
                ></el-checkbox>
                <!-- 【其它】課程：使用者自行填寫 -->
                <el-input
                  :key="`input-${course.id}`"
                  v-if="course.show_extra_input"
                  v-model="otherCourse"
                  :required="isOtherCourseChecked"
                  :disabled="!isOtherCourseChecked"
                  ref="otherCourse"
                  style="width: 250px"
                ></el-input>
              </div>
            </el-checkbox-group>
          </el-form-item>

          <!-- ===== 報名按鈕 ===== -->
          <el-form-item class="text-right">
            <el-button native-type="submit" type="primary">
              {{ $t("enrollment.step1.submitButton") }}
              <i class="el-icon-right"></i>
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="Available courses">
        <h2>You may enroll in one of the following courses</h2>
        <template v-if="ongoingCourses">
          <h3>Group Course 團體班</h3>
          <ol
            :key="ongoingCourse"
            v-for="ongoingCourse in Object.keys(ongoingCourses)"
          >
            <li>
              {{ ongoingCourse }}
              <ol
                :key="address"
                v-for="address in getSortedArray(
                  Object.keys(ongoingCourses[ongoingCourse])
                )"
              >
                <li>
                  {{ address }}
                  <ol
                    :key="ongoingClass"
                    v-for="ongoingClass in getSortedArray(
                      ongoingCourses[ongoingCourse][address]
                    )"
                  >
                    {{
                      ongoingClass.title
                    }}
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
          <h3>1-on-1 Private Lessons 個人課</h3>
          <ol>
            <li :key="privateLesson" v-for="privateLesson in privateLessons">
              {{ privateLesson }}
            </li>
          </ol>
          <h3>Mentor</h3>
          <ol>
            <li :key="counseling" v-for="counseling in counselings">
              {{ counseling }}
            </li>
          </ol>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

import formMixin from "@/mixins/form";
import enrollmentMixin from "@/mixins/enrollment";

import enrollmentApi from "@/views/enrollmentNew/apis/enrollmentNew";
import profileApi from "@/apis/profile";

export default {
  components: {},

  mixins: [formMixin, enrollmentMixin],

  props: ["enrollmentId"],
  data() {
    return {
      enrollmentFormInfo: null,
      courseList: [],
      ongoingCourses: null,

      // 選【其它】（關係、課程）：使用者需自行填寫
      otherRole: "",
      otherCourse: "",

      form: {
        role: "",
        first_name: "",
        email: "",
        phone: "",
        interested_courses: []
      },
      loginModal: false
    };
  },
  computed: {
    ...mapState("user", ["token", "lang"]),
    ...mapGetters("user", ["token", "getProfile"]),

    isLogin() {
      // TODO: 應該還要檢查 token 是否合法？
      return this.token !== "";
    },
    rules() {
      // 選【其它】（關係、課程）：使用者需自行填寫
      const validOtherRole = (rule, value, callback) => {
        if (!this.isOtherRole || this.otherRole) {
          callback();

          return;
        }

        callback(new Error());
      };
      const validOtherCourse = (rule, value, callback) => {
        if (!this.isOtherCourseChecked || this.otherCourse) {
          callback();

          return;
        }

        callback(new Error());
      };

      return {
        role: {
          validator: validOtherRole,
          message: this.$t("validation.required"),
          trigger: "blur"
        },
        interested_courses: {
          validator: validOtherCourse,
          message: this.$t("validation.required"),
          trigger: "blur"
        }
      };
    },

    // 關係（學生、老師、其它）讀 i18n 的資料
    roleList() {
      return this.$t("enrollment.step1.roleList");
    },
    // NOTE: 關係的最後一個必是【其它】
    otherRoleValue() {
      const roleList = this.roleList;

      return roleList[roleList.length - 1];
    },
    isOtherRole() {
      return this.form.role === this.otherRoleValue;
    },

    otherCourseValue() {
      if (!this.courseList.length) {
        return;
      }

      // 用 show_extra_input=1 判斷是否為【其它】
      const otherCourse = this.courseList.find(course => {
        return course.show_extra_input;
      });

      return otherCourse.name;
    },
    isOtherCourseChecked() {
      return this.form.interested_courses.indexOf(this.otherCourseValue) !== -1;
    },
    privateLessons() {
      return [
        "線上真人家教 Online (如SAT, ACT, TOEFL, AP...等)",
        "面對面真人家教 In-person (如SAT, ACT, TOEFL, AP...等)"
      ];
    },
    counselings() {
      return ["升學輔導 Mentoring (7-11 年級)", "申請顧問 (12 年級)"];
    }
  },
  watch: {
    isOtherRole(val) {
      if (!val) {
        return;
      }

      // 若選【其它】，需自行填寫關係（幫使用者 focus）
      this.$nextTick(() => {
        this.$refs["otherRole"][0].focus();
      });
    },
    isOtherCourseChecked(val) {
      if (!val) {
        return;
      }

      // 若選【其它】，需自行填寫課程（幫使用者 focus）
      this.$nextTick(() => {
        this.$refs["otherCourse"][0].focus();
      });
    }
  },
  async created() {
    //获取所有正在进行的课程
    const GROUP_CLASS = 1;
    const { course_sessions } = await enrollmentApi.fetchCourseSessions({
      type: GROUP_CLASS,
      group_by_city: 1
    });
    this.ongoingCourses = course_sessions;
  },
  async mounted() {
    if (this.isLogin) {
      this.$router.push({
        name: "QuickEnrollNew"
      });
    }
    if (this.enrollmentId && this.enrollmentId > 0) {
      const res = await enrollmentApi.getEnrollmentInfo(this.enrollmentId);
      this.enrollmentFormInfo = { ...res.enrollment_form };
      this.form["enrollment_form_id"] = this.enrollmentFormInfo.id;
      Object.keys(this.form).forEach(key => {
        if (this.enrollmentFormInfo[key] !== undefined) {
          this.form[key] = this.enrollmentFormInfo[key];
          if (key === "interested_courses") {
            this.form[key] = this.enrollmentFormInfo[key];
          } else {
            this.form[key] = this.enrollmentFormInfo[key];
          }
        }
      });
    }
    const sessionTagsRes = await enrollmentApi.fetchSessionTags();
    const { enrollment_session_tags } = sessionTagsRes;
    this.courseList = enrollment_session_tags;
  },

  methods: {
    async checkEmail(email) {
      if (!this.isLogin) {
        this.loginModal = true;
        const res = await profileApi.checkEmail(email);
        let isUser = res.is_user_existing;
        if (isUser) {
          this.$confirm(
            this.$t("enrollment.step1.This email has been registered", {
              email: email
            }),
            "",
            {
              confirmButtonText: this.$t("enrollment.step1.Log in"),
              cancelButtonText: this.$t("enrollment.step1.Use another email"),
              type: "warning"
            }
          )
            .then(() => {
              this.$router.push({
                name: "QuickEnrollNew"
              });
            })
            .catch(() => {
              this.form.email = "";
            });
        }
      }
    },
    getSortedArray(array) {
      let workArray = [...array];
      workArray.sort();
      return workArray;
    },
    async submit() {
      const form = JSON.parse(JSON.stringify(this.form));
      // 若選【其它】關係，要帶上使用者自行填寫的關係
      form.role = this.isOtherRole ? this.otherRole : form.role;
      // 若選【其它】課程，要帶上使用者自行填寫的課程
      if (this.isOtherCourseChecked) {
        form.interested_courses = form.interested_courses.map(course => {
          return course === this.otherCourseValue
            ? `*${this.otherCourseValue}: ${this.otherCourse}`
            : course;
        });
      }
      if (this.enrollmentId && this.enrollmentId > 0) {
        await enrollmentApi.updateStep1(form);
        this.$emit("toStep", "next", this.enrollmentId);
      } else {
        const enrollmentRes = await enrollmentApi.sendStep1(form);
        const { enrollment_form_id } = enrollmentRes;
        this.$emit("toStep", "next", enrollment_form_id);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  margin-bottom: 0;
}
::v-deep .el-form-item {
  margin-bottom: 40px;
}
h3 {
  font-size: 24px;
}

ol {
  list-style-type: none;
  padding-inline-start: 20px;
}

li {
  color: #444444;
}
::v-deep .el-form-item__content {
  margin-bottom: 20px;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
  margin-bottom: 0;
}
</style>
