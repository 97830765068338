/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  computed: {
    ...mapState("enrollment", ["enrollmentForm"]),
    ...mapGetters("enrollment", ["enrollmentFormId"])
  },

  methods: {
    ...mapActions("enrollment", ["keepEnrollmentForm"]),

    backToStep1() {
      this.routerPush({
        name: "EnrollmentNew"
      });
    }
  }
};
