import ApiService from "@/common/api.service";

export default {
  fetchSessionTags() {
    return ApiService.query("enrollment-session-tags");
  },
  fetchCourseSessions(params) {
    return ApiService.query("course-sessions-for-enrollment", params);
  },

  fetchEnrolledSessionOrder(enrolledSessionId) {
    return ApiService.get("enrollment-forms-order", enrolledSessionId);
  },

  getStudentParents(studentId) {
    return ApiService.query(`students/${studentId}/parents`);
  },
  quickEnrollParent(parentId, data) {
    return ApiService.post(`quick-enroll/parent/${parentId}`, data);
  },
  linkStudent(parentId, data) {
    return ApiService.post(
      `parent/${parentId}/require-student-connection`,
      data
    );
  },
  quickEnroll(id) {
    return ApiService.post(`quick-enroll/${id}`);
  },
  updateStep1(data) {
    return ApiService.patch("enroll-step-one", data);
  },
  sendStep1(data) {
    return ApiService.post("enroll-step-one", data);
  },
  getLatestEnrollmentInfo(studentId) {
    return ApiService.query(`quick-enroll/latest-enrollment/${studentId}`);
  },
  getEnrollmentInfo(id) {
    return ApiService.query(`enroll-step/${id}`);
  },
  sendStep2(data) {
    return ApiService.post("enroll-step-two", data);
  },
  sendStep2AndUpdateProfile(data) {
    return ApiService.patch("enrollment-forms-step-two", data);
  },
  sendStep3(data) {
    return ApiService.post("enroll-step-three", data);
  },

  createOrUpdateSessionOrder(enrolledSessionId, data) {
    return ApiService.post("enrollment-forms-order/" + enrolledSessionId, data);
  },

  fetchAll(data) {
    return ApiService.query("enrolled-course-sessions", data);
  },
  fetch(data) {
    return ApiService.query("enrolled-course-session-detail", data);
  },

  setStatus(data) {
    return ApiService.patch("set-enrolled-session-status", data);
  },

  read(data) {
    return ApiService.patch("read-enrolled-course-session", data);
  },
  markUnread(enrolledId) {
    return ApiService.patch("read-enrolled-course-session", {
      id: enrolledId,
      is_read: 0
    });
  },
  batchUpdateEnrollmentStatus(enrolled_session_ids, status) {
    return ApiService.patch("enrolled-session/batch/status", {
      enrolled_session_ids,
      status
    });
  },
  setPayFirstOrLater(data) {
    return ApiService.patch("set-enrolled-session-need-pay-first", data);
  },
  update(data) {
    return ApiService.patch("enrolled-course-session-detail-update", data);
  },

  addStudent(data) {
    return ApiService.post("add-student-to-enrolled-session", data);
  },
  addParent(data) {
    return ApiService.post("add-parent-to-enrolled-session", data);
  },

  addContact(data) {
    return ApiService.post("enrolled-session/contacts", data);
  },
  deleteContact(data) {
    return ApiService.delete("enrolled-session/contacts", data);
  },
  fetchContacts(id) {
    return ApiService.query("enrolled-session/contacts", id);
  },

  fetchTestPapers() {
    return ApiService.query("test-papers");
  },

  fetchPrivateLessons(data) {
    return ApiService.query("private-lessons", data);
  },

  fetchStudentClassTicket(id) {
    return ApiService.query("student-class-tickets/" + id);
  },
  deleteStudentClassTicket(id) {
    return ApiService.delete(`student-class-tickets/${id}`);
  },
  getAssignTest() {
    return ApiService.query("assign-test/");
  },
  updateAssignTest(id, data) {
    return ApiService.post("assign-test/" + id + "/mail", data);
  },
  getEnrollmentTest(id) {
    return ApiService.query("assign-test/" + id);
  },
  setEnrollmentTest(id) {
    return ApiService.post("assign-test/" + id);
  },
  updateEnrollmentPrice(id, data) {
    return ApiService.patch(`enrolled-session/${id}`, data);
  },
  storeContractOfEnrollment(data) {
    return ApiService.post("enrolled-session/contract", data);
  },
  storeSignedContractOfEnrollment(data) {
    return ApiService.post("enrolled-session/signed-contract", data);
  },
  sendContractOfEnrollment(data) {
    return ApiService.post("enrolled-session/send-contract-email", data);
  }
};
