<template>
  <div>
    <figure class="ivy-banner mb-3">
      <el-image
        src="/img/enrollment.jpeg"
        fit="cover"
        class="d-block"
      ></el-image>
      <figcaption class="font-weight-bolder">
        {{ $t("enrollment.title") }}
      </figcaption>
    </figure>
    <div id="step">
      <div v-if="activeStep === 1">
        <Step1 @toStep="toStep" :enrollmentId="enrollmentId"></Step1>
      </div>
      <div v-if="activeStep === 2">
        <Step2Quick
          v-if="isQuickEnroll"
          @toStep="toStep"
          :enrollmentId="enrollmentId"
        ></Step2Quick>
        <Step2 v-else @toStep="toStep" :enrollmentId="enrollmentId"></Step2>
      </div>
      <div v-if="activeStep === 3">
        <Step3 @toStep="toStep" :enrollmentId="enrollmentId" :isQuickEnroll="isQuickEnroll"></Step3>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

import enrollmentMixin from "@/mixins/enrollment";
import Step1 from "@/views/enrollmentNew/Step1";
import Step2 from "@/views/enrollmentNew/Step2";
import Step2Quick from "@/views/enrollmentNew/Step2Quick";
import Step3 from "@/views/enrollmentNew/Step3";

export default {
  metaInfo() {
    return {
      title: "Enrollment - Ivy-Way Academy"
    };
  },

  components: { Step1, Step2, Step2Quick, Step3 },

  mixins: [enrollmentMixin],

  props: [],
  data() {
    return {
      // activeStep: 1
    };
  },
  computed: {
    isQuickEnroll() {
      let isQuickEnroll = false;
      if (this.$route.query.isQuick) {
        isQuickEnroll = true;
      }
      return isQuickEnroll;
    },
    activeStep() {
      let activeStep = 1;
      if (this.$route.query.step) {
        activeStep = parseInt(this.$route.query.step);
      }
      return activeStep;
    },
    enrollmentId() {
      let enrollmentId = null;
      if (this.$route.query.enrollment_id) {
        enrollmentId = parseInt(this.$route.query.enrollment_id);
      }
      return enrollmentId;
    }
  },
  watch: {},

  mounted() {
    this.$nextTick(() => {
      if (this.enrollmentId && this.enrollmentId) {
        scrollTo(0, 600);
      }
    });
  },
  methods: {
    toStep(type, enrollmentId) {
      let toStep = 1;
      if (type === "next") {
        toStep = this.activeStep + 1;
      } else if (type === "back") {
        toStep = this.activeStep - 1;
      }
      this.$router.push({
        name: "EnrollmentNew",
        query: {
          ...this.$route.query,
          enrollment_id: enrollmentId,
          step: toStep
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/enrollment.scss";
</style>

<style lang="scss" scoped>
::v-deep .el-form-item__label,
::v-deep .el-collapse-item__header,
::v-deep .el-radio__label {
  font-size: 16px;
}
.ivy-banner {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .el-image {
    height: 600px;
  }

  figcaption {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 9;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: #fff;
  }
}
</style>
