<template>
  <div class="container">
    <!-- ===== 尚未完成報名 ===== -->
    <el-alert
      :title="$t('enrollment.step3.notYetFinishWarning')"
      :closable="false"
      type="warning"
      class="mb-3"
    ></el-alert>
    <el-form
      @submit.prevent.native="submit"
      :model="form"
      :validate-on-rule-change="false"
      :show-message="false"
      ref="form"
      label-position="left"
    >
      <!-- ===== 想上的課程 ===== -->
      <el-card shadow="never" class="course-sessions mb-3">
        <div
          :class="
            needShowRequiredStyle('interested_course_sessions')
              ? 'alertRequired'
              : ''
          "
        >
          <el-form-item
            :label="$t('enrollment.step3.enroll')"
            :rules="[commonRules.checkbox, rules.interested_course_sessions]"
            prop="interested_course_sessions"
            required
          >
            <!-- NOTE: 這邊有 hard code，要注意 -->
            <el-checkbox-group v-model="form.interested_course_sessions">
              <el-collapse class="ml-3 mr-3" v-model="activeTypes">
                <!-- 團體課 -->
                <el-collapse-item :title="typeList[0]" name="type1">
                  <el-collapse :value="sessionTags" v-model="sessionTags">
                    <el-collapse-item
                      v-for="(courseDetail, courseName) in courseList.type1"
                      :key="courseName"
                      :title="courseName"
                      :name="courseName"
                    >
                      <div
                        v-for="(sessions, city) in courseDetail"
                        :key="city"
                        class="ml-3"
                      >
                        <p>{{ city }}</p>
                        <el-checkbox
                          v-for="session in sessions"
                          :key="session.id"
                          :label="session.id"
                          class="mt-3 d-flex align-items-center"
                        >
                          {{ session.title }}
                        </el-checkbox>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </el-collapse-item>

                <!-- 家教 -->
                <el-collapse-item :title="typeList[1]" name="type2">
                  <div
                    v-for="(tutor, index) in tutorList"
                    :key="index"
                    class="d-flex flex-wrap align-items-center"
                    :class="{ 'mb-3': index === 0 }"
                  >
                    <el-checkbox :label="index + 1">
                      {{ tutor }}
                    </el-checkbox>
                    <!-- 線上 -->
                    <el-input
                      v-if="index === 0"
                      :placeholder="
                        $t('enrollment.step3.privateOther.question')
                      "
                      v-model="form.custom_online"
                      :required="isOnlineChecked"
                      :disabled="!isOnlineChecked"
                      ref="online"
                      class="ml-3"
                      style="width: 600px"
                    ></el-input>
                    <!-- 面對面 -->
                    <el-input
                      v-if="index === 1"
                      :placeholder="
                        $t('enrollment.step3.privateOther.question')
                      "
                      v-model="form.custom_offline"
                      :required="isOfflineChecked"
                      :disabled="!isOfflineChecked"
                      ref="offline"
                      class="ml-3"
                      style="width: 600px"
                    ></el-input>
                  </div>
                </el-collapse-item>

                <!-- 升學輔導 -->
                <el-collapse-item :title="typeList[2]" name="type3">
                  <div>
                    <el-checkbox :label="4">
                      {{
                        $t(
                          "enrollment.step3.Mentorship / College Admissions Counseling"
                        )
                      }}
                    </el-checkbox>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </el-card>
      <!-- ===== 之前的考試成績 ===== -->
      <Tests />
      <el-card shadow="hover" class="mb-3">
        <div>
          <div
            style="font-size: 16px; color: #606266; line-height: 40px; padding: 0 12px 0 0; box-sizing: border-box;"
          >
            {{ $t("enrollment.step3.testScores") }}
          </div>
          <div v-for="student in studentList" :key="student.id">
            <p>
              {{
                userMethods.displayName(
                  student.profile.first_name,
                  student.profile.last_name,
                  student.profile.chinese_name
                )
              }}
            </p>
            <el-select
              :placeholder="$t('enrollment.step3.selectTestScores')"
              @change="onStudentTestHistoryChange(student, $event)"
              v-model="student.testHistory"
              class="w-100"
              multiple
            >
              <el-option
                v-for="(testDetail, testName) in student.testScores"
                :key="testName"
                :label="testName.toUpperCase()"
                :value="testName"
              ></el-option>
            </el-select>

            <template v-for="(testDetail, testName) in student.testScores">
              <Tests
                :key="testName"
                v-if="testDetail.isSelected"
                :test="testDetail"
                @dataFromChild="setStudentTestScores(student, $event)"
              />
            </template>
            <hr />
          </div>
        </div>
      </el-card>

      <!-- ===== 是否上過類似課程 ===== -->
      <div
        :class="
          needShowRequiredStyle('had_similar_course') ? 'alertRequired' : ''
        "
      >
        <el-form-item
          :label="$t('enrollment.step3.similarClass.question')"
          :rules="commonRules.radio"
          prop="had_similar_course"
          required
        >
          <el-radio-group v-model="form.had_similar_course">
            <el-radio :label="1">
              {{ $t("enrollment.step3.radioOptions")[0] }}
            </el-radio>
            <el-radio :label="0">
              {{ $t("enrollment.step3.radioOptions")[1] }}
            </el-radio>
          </el-radio-group>
          <div class="clearfix"></div>
        </el-form-item>
      </div>

      <!-- 過去的上課經驗 -->
      <div v-if="form.had_similar_course" class="ml-3">
        <div
          :class="
            needShowRequiredStyle('past_course_info') ? 'alertRequired' : ''
          "
        >
          <el-form-item
            :label="$t('enrollment.step3.similarClass.whereAndWhen')"
            :rules="commonRules.input"
            prop="past_course_info"
            required
            class="mb-3"
          >
            <el-input v-model="form.past_course_info" required></el-input>
          </el-form-item>
        </div>
        <el-form-item :label="$t('enrollment.step3.similarClass.how')">
          <el-input
            type="textarea"
            v-model="form.past_course_exp"
            :rows="5"
          ></el-input>
        </el-form-item>
        <p>{{ $t("enrollment.step3.similarClass.notes") }}</p>
      </div>

      <!-- ===== 中文是否有幫助 ===== -->
      <div
        :class="
          needShowRequiredStyle('will_mandarin_help') ? 'alertRequired' : ''
        "
      >
        <el-form-item
          :label="$t('enrollment.step3.mandarin.question')"
          :rules="commonRules.radio"
          prop="will_mandarin_help"
          required
        >
          <el-row>
            <el-col :span="24">
              <el-radio-group v-model="form.will_mandarin_help">
                <el-radio
                  v-for="(option, index) in mandarinOptions"
                  :key="index"
                  :label="index"
                  class="d-block ml-12 mt-12"
                >
                  {{ option }}
                </el-radio>
              </el-radio-group>
            </el-col>
          </el-row>
        </el-form-item>
      </div>
      <!-- ===== 如何得知 Ivy Way ===== -->
      <el-form-item
        :label="$t('enrollment.step3.knowUs.question')"
        :rules="commonRules.input"
        prop="know_us"
        required
      >
        <el-input
          v-model="form.know_us"
          :placeholder="$t('enrollment.step3.knowUs.placeholder')"
          required
        ></el-input>
      </el-form-item>

      <!-- ===== 是否為舊生推薦 ===== -->
      <div
        :class="
          needShowRequiredStyle('is_student_referral') ? 'alertRequired' : ''
        "
      >
        <el-form-item
          :label="$t('enrollment.step3.refer.question-main')"
          :rules="commonRules.radio"
          prop="is_student_referral"
          required
        >
          <el-radio-group v-model="form.is_student_referral">
            <el-radio :label="1">
              {{ $t("enrollment.step3.radioOptions")[0] }}
            </el-radio>
            <el-radio :label="0">
              {{ $t("enrollment.step3.radioOptions")[1] }}
            </el-radio>
          </el-radio-group>
          <div class="clearfix"></div>
        </el-form-item>
      </div>

      <!-- 舊生資料 -->
      <div v-if="form.is_student_referral" class="ml-3">
        <el-form-item :label="$t('enrollment.step3.refer.question-sub')">
          <el-input
            type="textarea"
            v-model="form.referrer"
            :rows="3"
          ></el-input>
        </el-form-item>
        <p>{{ $t("enrollment.step3.refer.notes") }}</p>
      </div>

      <!-- ===== 是否團報 ===== -->
      <div
        :class="
          needShowRequiredStyle('enroll_with_others') ? 'alertRequired' : ''
        "
      >
        <el-form-item
          :label="$t('enrollment.step3.together.question-main')"
          :rules="commonRules.radio"
          prop="enroll_with_others"
          required
        >
          <el-radio-group v-model="form.enroll_with_others">
            <el-radio :label="1">
              {{ $t("enrollment.step3.radioOptions")[0] }}
            </el-radio>
            <el-radio :label="0">
              {{ $t("enrollment.step3.radioOptions")[1] }}
            </el-radio>
          </el-radio-group>
          <div class="clearfix"></div>
        </el-form-item>
      </div>

      <!-- 團報生資料 -->
      <div v-if="form.enroll_with_others" class="ml-3">
        <div
          :class="
            needShowRequiredStyle('other_students') ? 'alertRequired' : ''
          "
        >
          <el-form-item
            :label="$t('enrollment.step3.together.question-sub')"
            required
          >
            <el-input
              type="textarea"
              v-model="form.other_students"
              :rows="5"
            ></el-input>
          </el-form-item>
          <p>{{ $t("enrollment.step3.together.notes") }}</p>
        </div>
      </div>

      <!-- ===== 如何付費 ===== -->
      <div
        :class="needShowRequiredStyle('payment_method') ? 'alertRequired' : ''"
      >
        <el-form-item
          :label="$t('enrollment.step3.pay.question')"
          :rules="commonRules.radio"
          prop="currency"
          required
        >
          <el-radio-group v-model="form.payment_method" class="ml-3">
            <el-radio
              v-for="(pay, index) in payOptions"
              :key="index"
              :label="index + 1"
              class="d-block mt-3"
            >
              {{ pay.way }}
              <div class="clearfix"></div>
              <div
                v-if="form.payment_method === index + 1"
                :class="
                  needShowRequiredStyle('currency') ? 'alertRequired' : ''
                "
              >
                <!-- 幣別 -->
                <el-radio-group v-model="form.currency" class="ml-3">
                  <el-radio
                    v-for="(currency, key) in payOptions[index].currency"
                    :key="key"
                    :label="Number(key)"
                    class="d-block mt-3"
                  >
                    {{ currency }}
                  </el-radio>
                </el-radio-group>
              </div>
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <!-- ===== 補充 ===== -->
      <el-form-item :label="$t('enrollment.step3.other.question')">
        <el-input
          type="textarea"
          v-model="form.other_info"
          :placeholder="$t('enrollment.step3.other.placeholder')"
          :rows="5"
        ></el-input>
      </el-form-item>

      <!-- ===== 完成 ===== -->
      <el-form-item class="text-right submit-btns">
        <el-button type="warning" @click="back">
          <i class="el-icon-back"></i>
          {{ $t("enrollment.backButton") }}
        </el-button>
        <el-button native-type="submit" type="primary">
          {{ $t("enrollment.step3.submitButton") }}
          <i class="el-icon-right"></i>
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { user } from "@ivy-way/material";
import Tests from "@/components/test/Tests";

import formMixin from "@/mixins/form";
import enrollmentMixin from "@/mixins/enrollment";
import roleMixin from "@/mixins/role";
import enrollmentApi from "@/views/enrollmentNew/apis/enrollmentNew";
import testList from "@/data/tests.json";

export default {
  components: {
    Tests
  },

  mixins: [formMixin, enrollmentMixin, roleMixin],

  props: ["enrollmentId", "isQuickEnroll"],
  data() {
    return {
      activeTypes: ["type1", "type2", "type3"],
      sessionTags: [],
      enrollmentFormInfo: null,
      interestedCourseList: [],
      courseList: {
        // 團體班
        type1: [],
        // 升學輔導
        type3: []
      },

      // 學生資料、考試成績
      studentList: [],

      // 若 0 是有定義的值，只好設 null 為初始值（盡量避免）
      form: {
        enrollment_form_id: 0,
        interested_course_sessions: [],
        custom_online: "",
        custom_offline: "",
        test_scores: [],
        had_similar_course: null,
        past_course_info: "",
        past_course_exp: "",
        will_mandarin_help: null,
        know_us: "",
        is_student_referral: null,
        referrer: "",
        enroll_with_others: null,
        other_students: "",
        payment_method: null,
        currency: null,
        other_info: "",
        mentoring_courses: [],
        mail_to_admin: 1,
        mail_to_parents: 1,
        mail_to_student: 1
      },
      displayRequiredStyle: {}
    };
  },
  computed: {
    userMethods() {
      return user;
    },
    lang() {
      return this.$store.getters["user/langValue"];
    },
    langList() {
      return {
        "ZH-TW": "tw",
        "EN-US": "en",
        "ZH-CN": "cn"
      };
    },
    rules() {
      // 家教需自行填寫
      const validTutor = (rule, value, callback) => {
        const validOnline = this.isOnlineChecked && !this.form.custom_online;
        const validOffline = this.isOfflineChecked && !this.form.custom_offline;

        if (validOnline || validOffline) {
          callback(new Error());

          return;
        }

        callback();
      };

      return {
        interested_course_sessions: {
          validator: validTutor,
          message: this.$t("validation.required"),
          trigger: "blur"
        }
      };
    },

    // NOTE: 這邊有 hard code，要注意
    isOnlineChecked() {
      return this.form.interested_course_sessions.includes(1);
    },
    isOfflineChecked() {
      return this.form.interested_course_sessions.includes(2);
    },

    // 以下讀 i18n 的資料
    typeList() {
      return this.$t("enrollment.step3.typeList");
    },
    tutorList() {
      return this.$t("enrollment.step3.tutorList");
    },
    mandarinOptions() {
      return this.$t("enrollment.step3.mandarin.options");
    },
    payOptions() {
      return this.$t("enrollment.step3.pay.options");
    },
    courseSessions() {
      let courseSessions = [];
      for (const type in this.courseList) {
        for (const testType in this.courseList[type]) {
          const sessionIsMentor = type === "type3";
          if (sessionIsMentor) {
            courseSessions = [...courseSessions, ...this.courseList[type]];
          } else {
            for (const name in this.courseList[type][testType]) {
              courseSessions = [
                ...courseSessions,
                ...this.courseList[type][testType][name]
              ];
            }
          }
        }
      }
      const privateSessions = [
        {
          id: 1,
          title: { tw: "網路線上家教", en: "Online", cn: "网路线上家教" }
        },
        {
          id: 2,
          title: {
            tw: "面對面家教",
            en: "In-person Tutoring",
            cn: "面对面家教"
          }
        },
        {
          id: 3,
          title: {
            tw: "升學輔導 Mentoring (7-11 年級)",
            en: "Mentoring",
            cn: "升学辅导 Mentoring (7-11 年级)"
          }
        },
        {
          id: 4,
          title: {
            tw: "全年度 高中或大學顧問",
            en: "Counseling",
            cn: "全年度 高中或大学顾问"
          }
        },
        {
          id: 5,
          title: {
            tw: "線上升學輔導 Online Mentoring (7-11 年級)",
            en: "Online Mentoring",
            cn: "線上升學輔導 Online Mentoring (7-11 年級)"
          }
        },
        {
          id: 6,
          title: {
            tw: "線上全年度 高中或大學顧問 Online Counseling",
            en: "Online Counseling",
            cn: "线上全年度 高中或大学顾问 Online Counseling"
          }
        }
      ];
      return [
        ...courseSessions,
        ...privateSessions.map(privateSession => ({
          ...privateSession,
          title: privateSession.title[this.langList[this.lang]]
        }))
      ];
    }
  },
  watch: {
    lang() {
      this.getCourseSessions(1);
      this.getCourseSessions(3);
    },

    isOnlineChecked(val) {
      if (!val) {
        return;
      }

      // 線上家教需自行填寫（幫使用者 focus）
      this.$nextTick(() => {
        this.$refs["online"][0].focus();
      });
    },
    isOfflineChecked(val) {
      if (!val) {
        return;
      }

      // 面對面家教需自行填寫（幫使用者 focus）
      this.$nextTick(() => {
        this.$refs["offline"][0].focus();
      });
    },
    "form.payment_method"() {
      this.form.currency = 1;
    }
  },
  created() {},

  async mounted() {
    const form = this.form;

    form.enrollment_form_id = this.enrollmentId;

    this.getCourseSessions(1);
    this.getCourseSessions(3);

    const res = await enrollmentApi.getEnrollmentInfo(this.enrollmentId);
    this.enrollmentFormInfo = { ...res.enrollment_form };
    const enrollmentForm = this.enrollmentFormInfo;
    // const enrollmentForm = this.enrollmentForm;

    // step1 想上的課
    this.interestedCourseList = enrollmentForm.interested_courses;

    // step2 學生資料、考試
    enrollmentForm.enrolled_session_students.forEach(item => {
      const student = new Student();

      student.profile = item;

      testList.forEach(test => {
        test.isSelected = false;

        student.testScores[test.name] = JSON.parse(JSON.stringify(test));
      });

      this.studentList.push(student);
    });
    //快速報名的第三步需要填寫上一次完整的報名表信息；
    if (this.isQuickEnroll) {
      if (
        enrollmentForm.enrolled_session_students &&
        enrollmentForm.enrolled_session_students.length > 0
      ) {
        enrollmentForm.enrolled_session_students.forEach(
          async (student, index) => {
            const latestEnrollmentIdRes = await enrollmentApi.getLatestEnrollmentInfo(
              student.student_profile.user_id
            );
            let latestEnrollmentId = latestEnrollmentIdRes.enrollment_form_id;
            if (latestEnrollmentId) {
              const res = await enrollmentApi.getEnrollmentInfo(
                latestEnrollmentId
              );
              const latestEnrollment = res.enrollment_form;
              if (index === 0) {
                this.form.know_us = latestEnrollment.know_us;
                this.form.had_similar_course = latestEnrollment.had_similar_course;
                this.form.past_course_info = latestEnrollment.past_course_info;
                this.form.past_course_exp = latestEnrollment.past_course_exp;
                this.form.will_mandarin_help = latestEnrollment.will_mandarin_help;
                this.form.is_student_referral =
                  latestEnrollment.is_student_referral;
                this.form.referrer = latestEnrollment.referrer;
                this.form.enroll_with_others = latestEnrollment.enroll_with_others;
                this.form.other_students = latestEnrollment.other_students;
                this.form.payment_method = latestEnrollment.payment_method;
                this.form.currency = latestEnrollment.currency;
                this.form.other_info = latestEnrollment.other_info;
              }
              //计算分数
              if (
                latestEnrollment.test_scores &&
                latestEnrollment.test_scores.length > 0
              ) {
                this.studentList.forEach(student => {
                  latestEnrollment.test_scores.forEach(score => {
                    if (student.profile.id === score.student_user_id) {
                      let testHistory = [];
                      for (var b in student.testScores) {
                        for (var c in score.scores) {
                          if (c === b) {
                            testHistory.push(c);
                            student.testScores[b] = {
                              ...student.testScores[b],
                              ...score.scores[c][0]
                            };
                            student.testScores[b].isSelected = true;
                          }
                        }
                      }
                      student.testHistory = testHistory;
                    }
                  });
                });
              }
            }
          }
        );

        // if (latestEnrollmentId) {
        //   const res = await enrollmentApi.getEnrollmentInfo(latestEnrollmentId);
        //   const latestEnrollment = res.enrollment_form;
        //   if (
        //     latestEnrollment.test_scores &&
        //     latestEnrollment.test_scores.length > 0
        //   ) {
        //     this.studentList.forEach(student => {
        //       latestEnrollment.test_scores.forEach(score => {
        //         if (student.profile.id === score.student_user_id) {
        //           let testHistory = [];
        //           for (var b in student.testScores) {
        //             for (var c in score.scores) {
        //               if (c === b) {
        //                 testHistory.push(c);
        //                 student.testScores[b] = {
        //                   ...student.testScores[b],
        //                   ...score.scores[c][0]
        //                 };
        //                 student.testScores[b].isSelected = true;
        //               }
        //             }
        //           }
        //           student.testHistory = testHistory;
        //         }
        //       });
        //     });
        //   }
        //
        // }
      }
    }
  },

  methods: {
    // NOTE: 這邊有 hard code，要注意
    /*
    {
      1: 團體課
      2: 家教
      3: 升學輔導
    }
    */
    async getCourseSessions(type) {
      const group_by_city = type === 1 ? 1 : 0;

      const res = await enrollmentApi.fetchCourseSessions({
        type,
        group_by_city
      });

      const { course_sessions } = res;

      switch (type) {
        case 1:
          this.courseList.type1 = course_sessions;
          this.sessionTags = Object.keys(course_sessions);
          break;

        case 3:
          this.courseList.type3 = course_sessions.Mentor;
          break;
      }
    },

    async getGroupCourseSessions() {
      const type = 1;
      const group_by_city = 1;

      const res = await enrollmentApi.fetchCourseSessions({
        type,
        group_by_city
      });

      const { course_sessions } = res;
      this.courseList.type1 = course_sessions;
    },

    hasTaken(student, testName) {
      return student.testHistory.indexOf(testName) !== -1;
    },
    onStudentTestHistoryChange(student) {
      Object.keys(student.testScores).forEach(testName => {
        student.testScores[testName].isSelected = this.hasTaken(
          student,
          testName
        )
          ? true
          : false;
      });
    },
    setStudentTestScores(student, testScores) {
      student.testScores[testScores.name] = testScores;
    },
    needShowRequiredStyle(propName) {
      return this.displayRequiredStyle[propName];
    },
    setEmptyPropToDisplayRequired() {
      this.displayRequiredStyle = {
        interested_course_sessions:
          this.form.interested_course_sessions.length === 0,
        had_similar_course: this.form.had_similar_course === null,
        past_course_info: this.form.past_course_info === "",
        past_course_exp: this.form.past_course_info === "",
        will_mandarin_help: this.form.will_mandarin_help === null,
        is_student_referral: this.form.is_student_referral === null,
        referrer: this.form.referrer === "",
        enroll_with_others: this.form.enroll_with_others === null,
        other_students: this.form.other_students === "",
        payment_method: this.form.payment_method === null,
        currency: this.form.currency === null
      };
    },
    async submit() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        this.setEmptyPropToDisplayRequired();
        this.$alert(
          this.$t("enrollment.step3.checkInfo"),
          this.$t("enrollment.step3.warning"),
          {
            confirmButtonText: this.$t("enrollment.step3.check"),
            type: "warning"
          }
        ).then(() => {
          window.scrollTo(0, 640);
        });
        return;
      }

      const form = JSON.parse(JSON.stringify(this.form));
      const studentList = JSON.parse(JSON.stringify(this.studentList));

      // 家教梯次是 tutor-0、tutor-1（假的，不存在）、其他真實的梯次才有 id
      form.interested_course_sessions = form.interested_course_sessions.filter(
        item => {
          return item * 1;
        }
      );

      // 之前的考試成績
      studentList.forEach(student => {
        const testScores = new TestScores();

        testScores.student_user_id = student.profile.id;

        Object.keys(student.testScores).forEach(testName => {
          const test = student.testScores[testName];

          if (this.hasTaken(student, testName)) {
            delete test.name;
            delete test.value;
            delete test.isSelected;

            // 一個考試可能應試多次、有多次成績，先用 array 裝
            student.testScores[testName] = [test];
          } else {
            delete student.testScores[testName];
          }
        });

        testScores.scores = student.testScores;

        form.test_scores.push(testScores);
      });

      const enrollmentRes = await enrollmentApi.sendStep3(form);

      const { enrollment_form } = enrollmentRes;

      this.keepEnrollmentForm(enrollment_form);

      this.routerPush({
        name: "EnrollmentSuccess",
        query: {
          ...this.$route.query
        }
      });
    },
    back() {
      this.$emit("toStep", "back", this.enrollmentId);
    }
  }
};

class Student {
  profile = {};
  testHistory = [];
  testScores = {};
}

class TestScores {
  student_user_id = 0;
  scores = [];
}
</script>

<style scoped>
::v-deep .el-collapse,
::v-deep .el-collapse-item__header,
::v-deep .el-collapse-item__wrap {
  border: 0;
  overflow: auto;
}

::v-deep .el-collapse-item__arrow {
  /* display: none; */
}
::v-deep .el-form-item {
  margin-bottom: 4px;
}
::v-deep .el-form-item__label {
  float: none;
}
::v-deep .el-collapse-item__wrap {
  padding-left: 20px;
}
</style>

<style scoped>
.submit-btns {
  margin-top: 20px;
}

.alertRequired {
  border: 1px solid #d9534f;
  border-radius: 4px;
  padding: 0px 8px;
  margin: 8px 0px;
}
::v-deep .el-alert {
  padding: 20px 16px;
}

::v-deep .el-alert__title {
  font-size: 18px;
  font-weight: 700;
}
::v-deep .el-collapse-item {
  margin-bottom: 1rem;
}
::v-deep .el-collapse-item__content {
  padding-top: 1rem;
  padding-bottom: 0;
}
::v-deep .el-collapse-item__header {
  /* width: 50%; */
  border-radius: 0.2rem;
  padding-left: 1rem;
  color: #ffffff;
  background-color: #42a16a;
  border-color: #42a16a;
}
::v-deep .el-collapse-item__header:hover {
  opacity: 0.8;
}
::v-deep .el-collapse-item__content .el-collapse-item__header {
  border-radius: 0.2rem;
  margin-bottom: 0;
}

::v-deep .el-collapse-item__wrap {
  border: none;
}
</style>
