<template>
  <div class="container">
    <!-- ===== 尚未完成報名 ===== -->
    <el-alert
      :title="$t('enrollment.step2.warning')"
      :closable="false"
      type="warning"
      class="mb-3"
    ></el-alert>

    <el-form
      @submit.prevent.native="submit"
      :model="form"
      :validate-on-rule-change="false"
      ref="form"
      label-position="top"
      label-width="150px"
    >
      <!-- ===== 學生區塊 ===== -->
      <el-card shadow="hover" class="mb-3">
        <h3 class="mb-3">
          {{ $t("enrollment.step2.student.title") }}
        </h3>
        <!-- ===== 若多個學生同時報名，選擇的課程需相同 ===== -->
        <el-alert
          :title="$t('enrollment.step2.notes')"
          :closable="false"
          type="error"
          class="mb-3"
        ></el-alert>

        <!-- ===== 多個學生 ===== -->
        <div v-for="(student, index) in form.students" :key="index">
          <el-divider v-if="index" class="mt-5"></el-divider>
          <!-- 至少要有一個學生，不能全刪光 -->
          <el-form-item v-if="form.students.length > 1" class="text-right">
            <el-button @click="removeStudent(index)" type="danger" plain circle>
              <i class="el-icon-delete-solid"></i>
            </el-button>
          </el-form-item>

          <!-- ===== 學生姓名 ===== -->
          <el-form-item
            :label="$t('enrollment.step2.student.name.label')"
            required
          >
            <div class="row">
              <div
                class="col-12 col-sm-6 col-md-4 pt-0"
                v-show="!isChineseName"
              >
                <el-form-item
                  :rules="commonRules.input"
                  :prop="`students[${index}].first_name`"
                  required
                >
                  <el-input
                    :value="student.first_name"
                    @input="
                      value => {
                        setStudent(index, 'first_name', value);
                      }
                    "
                    :placeholder="
                      $t('enrollment.step2.student.name.placeholder.first')
                    "
                    required
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-12 col-sm-6 col-md-4 pt-0">
                <el-form-item
                  :rules="commonRules.input"
                  :prop="`students[${index}].last_name`"
                  required
                >
                  <el-input
                    :value="student.last_name"
                    @input="
                      value => {
                        setStudent(index, 'last_name', value);
                      }
                    "
                    :placeholder="
                      $t('enrollment.step2.student.name.placeholder.last')
                    "
                    required
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-12 col-sm-6 col-md-4 pt-0" v-show="isChineseName">
                <el-form-item
                  :rules="commonRules.input"
                  :prop="`students[${index}].first_name`"
                  required
                >
                  <el-input
                    :value="student.first_name"
                    @input="
                      value => {
                        setStudent(index, 'first_name', value);
                      }
                    "
                    :placeholder="
                      $t('enrollment.step2.student.name.placeholder.first')
                    "
                    required
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-12 col-sm-6 col-md-4 pt-0">
                <el-form-item>
                  <el-input
                    :value="student.chinese_name"
                    @input="
                      value => {
                        setStudent(index, 'chinese_name', value);
                      }
                    "
                    :placeholder="
                      $t('enrollment.step2.student.name.placeholder.other')
                    "
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form-item>

          <!-- ===== 學生 email ===== -->
          <el-form-item
            :label="$t('enrollment.step2.student.email.label')"
            :rules="
              doesNotParent || (student.id && student.id > 0)
                ? [commonRules.input, commonRules.email]
                : [
                    commonRules.input,
                    commonRules.email,
                    commonRules.checkEmails
                  ]
            "
            :prop="`students[${index}].email`"
            required
          >
            <el-input
              :value="student.email"
              @input="
                value => {
                  setStudent(index, 'email', value);
                }
              "
              type="email"
              :placeholder="$t('enrollment.step2.student.email.placeholder')"
              required
            ></el-input>
          </el-form-item>

          <!-- ===== 學生電話 ===== -->
          <el-form-item
            :label="$t('enrollment.step2.student.phone.label')"
            :rules="commonRules.phone"
            :prop="`students[${index}].phone`"
            required
          >
            <el-input
              :value="student.phone"
              @input="
                value => {
                  setStudent(index, 'phone', value);
                }
              "
              type="tel"
              :placeholder="$t('enrollment.step2.student.phone.placeholder')"
              required
            ></el-input>
          </el-form-item>

          <!-- ===== 學生學校 ===== -->
          <el-form-item
            :label="$t('enrollment.step2.student.school.label')"
            :rules="commonRules.input"
            :prop="`students[${index}].school`"
            required
          >
            <el-input
              :value="student.school"
              @input="
                value => {
                  setStudent(index, 'school', value);
                }
              "
              :placeholder="$t('enrollment.step2.student.school.placeholder')"
              required
            ></el-input>
          </el-form-item>

          <!-- ===== 學生畢業年份 ===== -->
          <el-form-item
            :label="$t('enrollment.step2.student.grade.label')"
            required
          >
            <GradeLevelSelector
              :value="
                student.graduation_year === ''
                  ? setStudent(index, 'graduation_year', '2024')
                  : student.graduation_year
              "
              @change="
                value => {
                  setStudent(index, 'graduation_year', value);
                }
              "
            />
          </el-form-item>

          <!-- ===== 學生地址 ===== -->
          <el-form-item
            :label="$t('enrollment.step2.student.address.label')"
            required
          >
            <el-input
              :value="student.address"
              @input="
                value => {
                  setStudent(index, 'address', value);
                }
              "
              :placeholder="$t('enrollment.step2.student.address.placeholder')"
            ></el-input>
          </el-form-item>

          <!-- ===== 學生Line ===== -->
          <el-form-item label="Line">
            <el-input
              :value="student.line_id"
              @input="
                value => {
                  setStudent(index, 'line_id', value);
                }
              "
              type="tel"
              :placeholder="$t('enrollment.step2.student.line.placeholder')"
            ></el-input>
          </el-form-item>
          <!-- ===== 學生家裡電話 ===== -->
          <el-form-item
            :label="$t('enrollment.step2.student.home.label')"
            :rules="commonRules.phone"
          >
            <el-input
              :value="student.home_phone"
              @input="
                value => {
                  setStudent(index, 'home_phone', value);
                }
              "
              type="tel"
              :placeholder="$t('enrollment.step2.student.home.placeholder')"
            ></el-input>
          </el-form-item>
        </div>

        <!-- ===== 新增學生 ===== -->
        <el-form-item class="text-right">
          <el-button @click="addStudent" type="primary" plain>
            <i class="el-icon-plus"></i>
            {{ $t("enrollment.step2.addStudentButton") }}
          </el-button>
        </el-form-item>
      </el-card>
      <el-checkbox
        v-if="show_is_student_as_parent"
        v-model="form.is_student_as_parent"
        border
      >
        {{ $t("enrollment.step2.is_student_as_parent") }}
      </el-checkbox>
      <template v-if="!form.is_student_as_parent">
        <!-- ===== 家長區塊 ===== -->
        <el-card shadow="hover" class="mb-3">
          <h3 class="mb-3">
            {{ $t("enrollment.step2.parent.title") }}
          </h3>

          <!-- ===== 家長姓名 ===== -->
          <el-form-item
            :label="$t('enrollment.step2.parent.name.label')"
            required
          >
            <div class="row">
              <div
                class="col-12 col-sm-6 col-md-4 pt-0"
                v-show="!isChineseName"
              >
                <el-form-item
                  :rules="commonRules.input"
                  prop="parent_first_name"
                  required
                >
                  <el-input
                    v-model="form.parent_first_name"
                    :placeholder="
                      $t('enrollment.step2.parent.name.placeholder.first')
                    "
                    required
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-12 col-sm-6 col-md-4 pt-0">
                <el-form-item
                  :rules="commonRules.input"
                  prop="parent_last_name"
                  required
                >
                  <el-input
                    v-model="form.parent_last_name"
                    :placeholder="
                      $t('enrollment.step2.parent.name.placeholder.last')
                    "
                    required
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-12 col-sm-6 col-md-4 pt-0" v-show="isChineseName">
                <el-form-item
                  :rules="commonRules.input"
                  prop="parent_first_name"
                  required
                >
                  <el-input
                    v-model="form.parent_first_name"
                    :placeholder="
                      $t('enrollment.step2.parent.name.placeholder.first')
                    "
                    required
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-12 col-sm-6 col-md-4 pt-0">
                <el-form-item>
                  <el-input
                    v-model="form.parent_chinese_name"
                    :placeholder="
                      $t('enrollment.step2.parent.name.placeholder.other')
                    "
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form-item>

          <!-- ===== 家長 email ===== -->
          <el-form-item
            :label="$t('enrollment.step2.parent.email.label')"
            :rules="[
              commonRules.input,
              commonRules.email,
              commonRules.checkEmails
            ]"
            prop="parent_email"
            required
          >
            <el-input
              v-model="form.parent_email"
              type="email"
              :placeholder="$t('enrollment.step2.parent.email.placeholder')"
            ></el-input>
          </el-form-item>

          <!-- ===== 家長電話 ===== -->
          <el-form-item
            :label="$t('enrollment.step2.parent.phone.label')"
            :rules="commonRules.phone"
            prop="parent_phone"
            required
          >
            <el-input
              v-model="form.parent_phone"
              type="tel"
              :placeholder="$t('enrollment.step2.parent.phone.placeholder')"
            ></el-input>
          </el-form-item>
          <!-- ===== 家長Line ===== -->
          <el-form-item label="Line">
            <el-input
              v-model="form.parent_line_id"
              :placeholder="$t('enrollment.step2.student.line.placeholder')"
            ></el-input>
          </el-form-item>

          <!-- ===== 家長家裡電話 ===== -->
          <el-form-item
            :label="$t('enrollment.step2.parent.home.label')"
            :rules="commonRules.phone"
          >
            <el-input
              v-model="form.parent_home_phone"
              type="tel"
              :placeholder="$t('enrollment.step2.parent.home.placeholder')"
            ></el-input>
          </el-form-item>
        </el-card>
      </template>

      <!-- ===== 下一步 ===== -->
      <el-form-item class="text-right">
        <el-button type="warning" @click="back">
          <i class="el-icon-back"></i>
          {{ $t("enrollment.backButton") }}
        </el-button>
        <el-button native-type="submit" type="primary">
          {{ $t("enrollment.step2.submitButton") }}
          <i class="el-icon-right"></i>
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

import { GradeLevelSelector } from "@/components/selector";
import formMixin from "@/mixins/form";
import enrollmentMixin from "@/mixins/enrollment";

import enrollmentApi from "@/views/enrollmentNew/apis/enrollmentNew";
import profileApi from "@/apis/profile";
import usersApi from "@/apis/users";

export default {
  components: {
    GradeLevelSelector
  },

  mixins: [formMixin, enrollmentMixin],

  props: ["enrollmentId"],
  data() {
    return {
      doesNotParent: false,
      enrollmentFormInfo: null,
      parents: [],
      form: {
        enrollment_form_id: 0,
        students: [],
        parent_id: null,
        parent_first_name: "",
        parent_last_name: "",
        parent_chinese_name: "",
        parent_email: "",
        parent_phone: "",
        parent_home_phone: "",
        parent_line_id: "",
        parent_is_student_self: false,
        is_student_as_parent: false
      },
      show_is_student_as_parent: true
    };
  },
  computed: {
    // 關係（學生、老師、其它）讀 i18n 的資料
    roleList() {
      return this.$t("enrollment.step1.roleList");
    },
    isChineseName() {
      return false;
    }
  },
  watch: {},
  async created() {},
  async mounted() {
    // const enrollmentFormId = this.enrollmentFormId;
    const enrollmentFormId = this.enrollmentId;
    // 報名表建立成功才能進到後續步驟
    if (!enrollmentFormId) {
      this.backToStep1();
      return;
    }
    const form = this.form;
    form.enrollment_form_id = enrollmentFormId;

    const res = await enrollmentApi.getEnrollmentInfo(enrollmentFormId);

    this.enrollmentFormInfo = { ...res.enrollment_form };
    this.addStudent();
    const student = this.form.students[0];
    const enrollmentForm = this.enrollmentFormInfo;

    const role = enrollmentForm.role;

    /*
      0: 學生
      1: 家長
      2: 其它
    */
    if (
      enrollmentForm.parent_user !== null &&
      enrollmentForm.enrolled_session_students.length > 0
    ) {
      form.is_student_as_parent =
        enrollmentForm.is_student_as_parent === 1 ? true : false;
      if (!form.is_student_as_parent) {
        form.parent_id = enrollmentForm.parent_user.id;
        form.parent_first_name = enrollmentForm.parent_user.first_name;
        form.parent_last_name = enrollmentForm.parent_user.last_name;
        form.parent_chinese_name = enrollmentForm.parent_user.chinese_name;
        form.parent_email = enrollmentForm.parent_user.email;
        form.parent_phone = enrollmentForm.parent_user.phone;
        form.parent_home_phone = enrollmentForm.parent_user.home_phone;
        form.parent_line_id = enrollmentForm.parent_user.line_id;
      }
      form.students = [];
      enrollmentForm.enrolled_session_students.forEach(element => {
        element["school"] = element.student_profile.high_school;
        element["address"] = element.student_profile.living;
        element["graduation_year"] =
          element.student_profile.high_school_graduation_year;
        form.students.push(element);
      });
    } else {
      const roleEnum = this.roleList.indexOf(role);

      switch (roleEnum) {
        // student
        case 0:
          student.first_name = enrollmentForm.first_name;
          student.email = enrollmentForm.email;
          student.phone = enrollmentForm.phone;
          this.show_is_student_as_parent = true;
          this.form.is_student_as_parent = false;
          break;
        // parent
        case 1:
          form.parent_first_name = enrollmentForm.first_name;
          form.parent_email = enrollmentForm.email;
          form.parent_phone = enrollmentForm.phone;
          break;
      }
    }
  },

  methods: {
    addStudent() {
      const student = new Student();
      this.form.students.push(student);
    },
    removeStudent(index) {
      this.form.students.splice(index, 1);
    },
    setStudent(index, key, value) {
      this.form.students[index] = {
        ...this.form.students[index],
        [key]: value
      };

      this.form.students = [...this.form.students];
    },
    async verifyIfEmailAlreadyExist() {
      const checkEmailPromises = [];
      this.form.students.forEach(student => {
        if (!(student.id && student.id > 0)) {
          checkEmailPromises.push(
            usersApi.checkIfEmailAlreadyTaken(student.email)
          );
        }
      });
      const resultOfCheckEmails = await Promise.all(checkEmailPromises);
      const emailsAlreadyTaken = resultOfCheckEmails.filter(
        result => result.is_user_existing
      );
      if (emailsAlreadyTaken.length === 0) {
        return true;
      }
      await this.$alert(
        `${this.$t("enrollment.step2.thisMailAlreadyInUse1")}${
          emailsAlreadyTaken[0].email
        }${this.$t("enrollment.step2.thisMailAlreadyInUse2")}`,
        this.$t("message.notice"),
        { confirmButtonText: this.$t("message.continue") }
      );
      return false;
    },
    async submit() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        return;
      }

      const form = JSON.parse(JSON.stringify(this.form));
      if (!(await this.verifyIfEmailAlreadyExist())) return;

      let enrollmentRes = null;
      enrollmentRes = await enrollmentApi.sendStep2(form);

      const { enrollment_form } = enrollmentRes;
      this.keepEnrollmentForm(enrollment_form);
      this.$emit("toStep", "next", this.enrollmentId);
    },
    back() {
      this.$emit("toStep", "back", this.enrollmentId);
    }
  }
};

class Student {
  first_name = "";
  last_name = "";
  chinese_name = "";
  email = "";
  phone = "";
  home_phone = "";
  school = "";
  graduation_year = "";
  address = "";
}
</script>

<style scoped>
::v-deep .el-alert {
  padding: 20px 16px;
}

::v-deep .el-alert__title {
  font-size: 18px;
  font-weight: 700;
}
::v-deep .el-form-item__content {
  margin-bottom: 20px;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
  margin-bottom: 0;
}
</style>
